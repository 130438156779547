<template>
  <div class="modal-error-bg">
    <div class="modal-error">
      <h1>Oops! Somethign went wrong...</h1>
      <!-- <br> -->
      <p style="line-height: 1.6rem;">Please make sure the given <strong>data is legit and structured correctly</strong> and <strong>at least provide file for links</strong>!</p>
      <br /><br />
      <button @click="closeModal">Got it</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalError',

  methods: {
    closeModal() {
      this.$store.dispatch('app/SET_MODAL', '')
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-error-bg {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  padding: 3.5rem 1.4rem; // nämä säädetään state.ui:n mukaan myöhemmin!
  background-color: rgba(0, 0, 0, 0.5);
}

$modal-padding: 2rem;

.modal-error {
  position: relative;
  margin: 0 auto;
  max-width: 450px;
  min-width: 250px;
  padding: $modal-padding;
  padding-top: calc(#{$modal-padding} * 1.2);
  border-radius: 4px;
  background-color: white;
  box-shadow:
    0.5px 1px 3px rgba(0, 0, 0, 0.25),
    0 15px 30px 0 rgba(0, 0, 0, 0.11),
    0 5px 15px 0 rgba(0, 0, 0, 0.08);
  text-align: center;
  .close-modal {
    position: absolute;
    top: calc(#{$modal-padding} * 0.4);
    right: calc(#{$modal-padding} * 0.45);
    font-size: 0.9rem;
    cursor: pointer;
  }
}
</style>
